import React from "react";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="Nosotros">
      <CommonSection title="Sobre nosotros" />
      <AboutSection aboutClass="aboutPage" />
    </Helmet>
  );
};

export default About;
