import React from "react";
import { Container, Row, Col } from "reactstrap";
import aboutImg from "../../assets/all-images/cars-img/about.png";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage" ? { marginTop: "0px" } : { marginTop: "0px" }
      }
    >
      <Container>
        <Row>
          <Col lg="8" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">Sobre nosotros</h4>
              <h2 className="section__title">Preguntas frecuentes</h2>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-1">
                  <i class="ri-checkbox-circle-line"></i>¿Dónde se retira el
                  auto?
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center">
                  - Se retira en Garzon 1421 esq. Cam. Edison, Montevideo.
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-1">
                  <i class="ri-checkbox-circle-line"></i>¿Tiene límite de
                  kilometraje?
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center">
                  - Kilometraje libre.
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-1">
                  <i class="ri-checkbox-circle-line"></i>¿Incluye telepeaje?
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center">
                  - No incluye telepeaje, se abona en la devolución del
                  vehículo.
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-1">
                  <i class="ri-checkbox-circle-line"></i>¿Hay mínimo de días
                  para alquilar?
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center">
                  - 3 días.
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-1">
                  <i class="ri-checkbox-circle-line"></i>¿Qué pasa en caso de
                  tener multa?
                </p>
              </div>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center">
                  - Se puede pagar directamente en Red Pagos o Abitab o por
                  transferencia a nuestra cuenta.
                </p>
              </div>
            </div>
          </Col>
          <Col lg="4" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
