import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="#" className="d-flex align-items-center gap-2">
                  <i class="ri-car-line"></i>
                  <span>BG Rent a Car</span>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
              <p className="office__info">Garzon 1421 esq. Cam. Edison</p>
              <p className="office__info">+598 96 406 052</p>
              <p className="office__info">MAILBRUNO@gmail.com</p>
              <p className="office__info">Lunes a Viernes 9hs - 18hs</p>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
