import img01 from "../all-images/cars-img/gol.png";
import img02 from "../all-images/cars-img/unoWay.png";
import img03 from "../all-images/cars-img/kwid.png";

const carData = [
  {
    id: 1,
    carName: "Volkswagen Gol",
    imgUrl: img01,
    model: "Gol",
    automatic: "Manual",
  },

  {
    id: 2,
    carName: "Fiat Uno Way",
    imgUrl: img02,
    model: "Uno",
    automatic: "Manual",
  },

  {
    id: 3,
    carName: "Renault Kwid",
    imgUrl: img03,
    model: "Kwid",
    automatic: "Manual",
  },
];

export default carData;
